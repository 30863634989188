<template>
  <div class="container">
    <ul class="settings-container">
      <li v-for="(name, s) in settings" :key="s" class="setting-box">
        <p>{{ formatSettingName(s) }}</p>
        <ToggleSwitch
          :switchId="'switch-' + s + site"
          :toggle="toggleObject(settings[s], s)"
          @toggle-action="saveSetting(s, settings[s])"
        />
      </li>
    </ul>
  </div>
</template>

<script>
// import Toggle from "@vueform/toggle";
import ToggleSwitch from "@/components/_layouts/ToggleSwitch.vue";
import { mapGetters } from "vuex";

export default {
  name: "DirectSettings",
  components: {
    ToggleSwitch,
  },
  data() {
    return {
      currentSite: "",
      settings: {},
    };
  },

  props: {
    site: {
      type: String,
      required: true,
    },
  },

  mounted() {
    this.filteredSettings();
    this.currentSite = this.site;
    // console.log(this.currentSite);
    console.log(this.currentSite);
  },

  computed: {
    ...mapGetters({
      settingsViaSite: "settingsViaSite",
      channels: "channels",
    }),
  },

  methods: {
    toggleObject(contains, name) {
      return { contains: contains, name: name };
    },

    filteredSettings() {
      let s = this.settingsViaSite(this.site).filter(
        (setting) => typeof setting[1] === "boolean"
      );
      s.map((i) => (this.settings[i[0]] = i[1]));
      return this.settings;
    },

    formatSettingName(setting) {
      if (typeof setting == "string" && setting.includes("_")) {
        setting = setting.replace(/_/g, " ");
      } else if (typeof setting == "string" && setting.match(/([A-Z])/g)) {
        setting = setting.replace(/([A-Z])/g, " $1");
      }
      return setting + "?";
    },

    saveSetting(s, value) {
      this.channels.map((channel) => {
        let topic = channel.topic.split(":");
        if (topic[0] == "pos" && topic[2] == this.site) {
          console.log(topic, this.site, this.currentSite);
          channel.push("company:site:setting", { key: s, value: value });
        }
      });
    },
  },
  watch: {},
};
</script>

<style src="@vueform/toggle/themes/default.css"></style>
<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 95%;
  padding: 0 1rem;
}

.settings-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  list-style: none;
  padding: 1rem;
  margin: 0;
  background: var(--background-fade);
  border-radius: 0.5rem;
  box-shadow: var(--box-shadow);
  width: 100%;
}

.setting-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 11rem;
  height: 100%;
  padding: 0.5rem 0.5rem;
  border: none;
  box-shadow: var(--box-shadow);
  border-radius: 5px;
  margin: 0.5rem;
  background-color: var(--primary-colour);
  p {
    text-wrap: nowrap;
    margin: 0.5rem 0 0 0;
    text-transform: capitalize;
  }
}
@media only screen and (orientation: portrait) {
  .container {
    width: 100%;
    padding: 0;
  }
  .settings-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 0;
    align-items: center;
    justify-content: center;
    width: 90%;
  }
  .setting-box {
    width: 70%;
    height: 5rem;
    margin: 0.25rem;
  }
}
</style>
