<template>
  <Authenticated>
    <div class="dashboard-parent">

      <div class="dashboard-grid">
        <div class="section-one square">
          <div class="inner">
            <EditDash class="edit-icon" @click.stop.prevent="editCurrentDash" />
            <div class="title">
              <ReportsComponent dataType="sales" :site="site" :day="day" :otherDay="otherDay" :period="'today'" class="scaleDown" />
            </div>
          </div>
        </div>

        <div class="section-two square">
          <div class="inner">
            <EditDash class="edit-icon" />
            <div class="title">
              <ReportsComponent dataType="count" :site="site" :day="day" :otherDay="otherDay" :period="'today'" class="scaleDown" />
            </div>
          </div>
        </div>

        <div class="section-three square">
          <div class="inner">
            <EditDash class="edit-icon" />
          </div>
        </div>

        <div class="section-four square">
          <div class="inner">
            <EditDash class="edit-icon" />
          </div>
        </div>

        <div class="section-five square">
          <div class="inner">
            <EditDash class="edit-icon" />
            <div v-for="(site, index) in active" :key="site + index" class="list-container">
              {{ site.site }}

              <ul v-for="(cat, index) in Object.keys(site.stations)" :key="cat + index">
                {{
                  cat
                }}
                <template v-for="(station, index) in site.stations[cat]" :key="station + index">
                  <li v-if="station.active">
                    {{ station.name }}
                  </li>
                </template>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Authenticated>
</template>

<script>
import Authenticated from "@/components/_layouts/Authenticated";
import createdMixin from "@/components/_mixins/createdMixin";
import { mapGetters } from "vuex";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import dayOfYear from "dayjs/plugin/dayOfYear";
import { dayRange } from "@/helpers/time";
import ReportsComponent from "@/components/Reports/ReportsComponent";
import EditDash from "@/components/Dashboard/EditDash";
// import BarChart from "@/components/Reports/BarChart";

dayjs.extend(utc);
dayjs.extend(dayOfYear);

export default {
  name: "app",
  mixins: [createdMixin],
  components: { Authenticated, ReportsComponent, EditDash },
  emits: ["handleMenu"],

  data() {
    return {
      site: "all",
      day: dayRange.getToday(),
      otherDay: dayRange.getLastWeekDay(),
      dataType: "",
      period: "",
      edit: false,
    };
  },
  computed: {
    ...mapGetters({
      reports: "reports",
      siteSettings: "siteSettings",
      siteMap: "siteMap",
    }),

    active() {
      return this.siteMap.filter((site) => site);
    },
  },
  methods: {
    editCurrentDash() {
      this.edit = !this.edit;
      // console.log(this.edit);
    },
  },

};
</script>

<style scoped lang="scss">
.dashboard-parent {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-color: var(--secondary-colour);
  padding: 0;
  box-sizing: border-box;

  

  .store-nav {
    height: 2rem;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0;
    padding: 0;
    text-align: center;
    color: var(--text-colour);

    li {
      list-style: none;
      margin: 0 1rem;
      padding: 0;
      font-size: 1rem;
      font-weight: bolder;
      &:hover {
        cursor: pointer;
        color: var(--action-colour);
      }
    }
  }

  .dashboard-grid {
    // padding: 0.5rem 0 0 0;
    position: relative;
    height: calc(100vh - 0.5rem);
    width: calc(100% - 5.5rem);
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    color: var(--text-colour);
    z-index: 100;
    margin: 0.25rem;
    .square {
      padding: 0.5rem;
      overflow-y: scroll;
      .inner {
        position: relative;
        height: 100%;
        width: 100%;
        background-color: var(--primary-colour);
        box-shadow: var(--box-shadow);
        border-radius: 5px;
        text-transform: capitalize;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        overflow-y: scroll;
        .edit-icon {
          position: absolute;
          right: 0;
          display: inherit;
          margin-left: auto;
          z-index: 0;
          padding: 0.25rem;
          opacity: 0.3;
        }
        .list-container {
          padding: 0.5rem;
          margin: 0.5rem;
          border-radius: 5px;
          ul {
            width: 100%;
            text-transform: uppercase;
            color: var(--text-colour);
            background-color: var(--secondary-colour);
            border-radius: 5px;
            padding: 0.5rem 1rem;
            list-style-type: none;
            box-shadow: var(--box-shadow);
            li {
              color: var(--action-colour);
              list-style-type: circle;
              margin-left: 1rem;
              text-transform: capitalize;
            }
          }
        }

        .title {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 0.5rem;
          position: relative;
          cursor: pointer;
          z-index: 10;
          top: 15px;
          h3 {
            margin: 0;
            padding: 0;
            text-align: center;
            font-size: 1.5rem;
            font-weight: bolder;
            line-height: 1.5rem;
          }
        }
        .amount {
          font-size: 2rem;
          font-weight: bolder;
          line-height: 2rem;
        }
      }
      .center {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }

    .section-one {
      grid-area: 1 / 1 / 2 / 2;
    }
    .section-two {
      grid-area: 1 / 2 / 2 / 3;
    }
    .section-three {
      grid-area: 2 / 1 / 4 / 3;
    }
    .section-four {
      grid-area: 1 / 3 / 4 / 4;
    }
    .section-five {
      grid-area: 1 / 4 / 4 / 5;
      // #text {

      // }
    }
  }
}

.store-wrapper {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  .main {
    flex: 1 1;
    height: 100vh;
    overflow-y: scroll;
  }
}

@media only screen and (orientation: portrait) {
  .dashboard-parent {
    background-color: var(--secondary-colour);
    height: calc(100vh - 0.5rem);
    // width: calc(100vw - -5rem);
    margin-top: 2em;
    margin-left: 0;
    overflow: hidden;
    .inner {
      .title {
        height: 70%;
      }
    }
    .dashboard-grid {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(6, 1fr);
      width: 98%;
      .section-1 {
        grid-area: 1 / 1 / 2 / 2;
      }
      .section-two {
        grid-area: 1 / 2 / 2 / 3;
      }
      .section-three {
        grid-area: 2 / 1 / 4 / 3;
      }
      .section-four {
        grid-area: 4 / 1 / 7 / 2;
      }
      .section-five {
        grid-area: 4 / 2 / 7 / 3;
      }
    }
  }
}
</style>
