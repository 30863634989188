import store from '@/main.js';

class Costing {
  cost(recipe) {
    let price = [];
    let recipe_price = [];

    if ("value" in recipe && "ingredients" in recipe.value) {
      price = recipe.value.ingredients.map((ingredient) => {
        let i = store.getters.findIngredientByID(ingredient.id);
        let gst_excl = i.value.productGSTInclusive ? i.value.price_per_uom.split(" ")[0] / 1.15 : i.value.price_per_uom.split(" ")[0];
        if (i.value.price_per_uom.split(" ")[2] == ingredient.uom) {
          return { price: gst_excl * ingredient.usage };
        } else {
          if ("uom_relationship" in ingredient) {
            return { price: gst_excl * (ingredient.usage / ingredient.uom_relationship) };
          }
          return { price: 0 };
        }
      });
    }
    if ("value" in recipe && "recipes" in recipe.value) {
      recipe_price = recipe.value.recipes.map((recipe) => {
        let r = store.getters.findRecipeByID(recipe.id);
        if (r.value.cost_per_serving) {
          return { price: r.value.cost_per_serving };
        } else if (r.value.price_per_uom.split(" ")[2] == recipe.uom) {
          return { price: r.value.price_per_uom.split(" ")[0] * recipe.usage };
        } else {
          if ("uom_relationship" in recipe) {
            return { price: r.value.price_per_uom.split(" ")[0] * (recipe.usage / recipe.uom_relationship) };
          }
          return { price: 0 };
        }
        });
    }
    let reducePrice = price.reduce((acc, prod) => {
      return (acc += prod.price);
      }, 0);
    let reduceMenu = recipe_price.reduce((acc, prod) => {
      return (acc += prod.price);
    }, 0);
    return [price, reducePrice, recipe_price, reduceMenu, reducePrice + reduceMenu]; 
  }
}

const costingCalc = new Costing();
export { costingCalc };
