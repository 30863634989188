<template>
  <Authenticated>
    <div class="tab-buttons">
      <button
        class="tab-btn"
        data-tab="products"
        @click.stop.prevent="showProductTab"
      >
        Products
      </button>
      <button
        class="tab-btn"
        data-tab="modifiers"
        @click.stop.prevent="showModifierTab"
      >
        Modifiers
      </button>
    </div>
    <section class="tab-container">
      <section v-if="modifierTab" class="tab">
        <h2>Modifiers Sets: {{ site }}</h2>
        <div class="section">
          <div
            tabindex="0"
            v-for="(modifierset, idx) in modifiersets"
            :key="modifierset + idx"
            class="product-grid-container"
          >
            <div class="bin">
              <h3>{{ modifierset.value.name }}</h3>
              <font-awesome-icon
                icon="trash"
                @click.stop.prevent="check(idx, modifierset, 'modifier')"
              />
            </div>
            <div class="product-grid">
              <div
                tabindex="0"
                v-for="modifier in modifierset.value.modifiers"
                :key="modifier"
                class="product"
                @click.stop.prevent="openModifierModal(modifier)"
              >
                <p>{{ modifier.name }}</p>
              </div>
              <div
                class="product"
                @click.stop.prevent="openModifierModal('addNew')"
              >
                <p>Add New Modifier</p>
              </div>
            </div>
          </div>
          <h3>Add new Modifier Set</h3>
          <div class="product-grid">
            <div class="product" @click.stop.prevent="add('modifierSet')">
              <p>Add New Modifier Set</p>
            </div>
          </div>
        </div>
      </section>
      <section v-if="productTab" class="section tab">
        <h2>Products: {{ site }}</h2>
        <div
          v-for="(category, idx) in POSCategories(site)"
          :key="category"
          class="product-grid-container"
        >
          <div v-if="category.value.name !== 'Catering'" class="bin">
            <h3 v-if="category.value.name !== 'Catering'">
              {{ category.value.name }}
            </h3>
            <font-awesome-icon
              icon="trash"
              @click.stop.prevent="check(idx, category, 'category')"
            />
          </div>

          <div v-if="category.value.name !== 'Catering'" class="product-grid">
            <div
              tabindex="0"
              v-for="product in category.value.products"
              :key="product"
              class="product"
              @click.stop.prevent="openModal(product)"
            >
              <p class="none">
                {{ (prod = POSProductLookup({ site: site, prod: product })) }}
              </p>
              <p v-if="prod">{{ prod.value.name }}</p>
              <p v-if="prod">${{ prod.value.price }}</p>
            </div>
            <div class="product" @click.stop.prevent="openModal('addNew')">
              Add New Product
            </div>
          </div>
        </div>
        <h3>Add new Product Category</h3>
        <div class="product-grid">
          <div class="product" @click.stop.prevent="add('category')">
            <p>Add New Product category</p>
          </div>
        </div>
      </section>
    </section>
    <section v-if="newModSet || newCategory" class="check-container">
      <div class="check-content">
        <div class="set-name">
          <div v-if="labelInput.value == 'mod'">
            <label for="name"
              ><h3>{{ labelInput.label }}:</h3></label
            >
            <input type="text" v-model="newModSetName" />
            <div class="btns">
              <button @click.stop.prevent="addNewModifierSet">Create</button>
              <button @click.stop.prevent="newModSet = !newModSet">
                Cancel
              </button>
            </div>
          </div>
          <div v-if="labelInput.value == 'cat'">
            <label for="name"
              ><h3>{{ labelInput.label }}:</h3></label
            >
            <input type="text" v-model="newCategoryName" />
            <div class="btns">
              <button @click.stop.prevent="addCategory">Create</button>
              <button @click.stop.prevent="newCategory = !newCategory">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section v-if="deleteCheck" class="check-container">
      <div class="check-content">
        <h3>Are you sure you want to delete this {{ type }}?</h3>
        <div class="btns">
          <button @click.stop.prevent="confirmDelete(type)">Delete</button>
          <button @click.stop.prevent="deleteCheck = !deleteCheck">
            Cancel
          </button>
        </div>
      </div>
    </section>
    <ModifierModal
      :modifier="selectedModifier"
      :open="showModifierModal"
      @close-modal="showModifierTab"
    />
    <ProductModal
      :product="product.value"
      :open="showModal"
      :modifierSets="modifiersets"
      @save="saveProduct"
      @remove="removeProduct"
      @close-modal="showProductTab"
    />
  </Authenticated>
</template>

<script>
import { mapGetters } from "vuex";
import Authenticated from "../_layouts/Authenticated.vue";
import createdMixin from "@/components/_mixins/createdMixin";
import ProductModal from "@/components/Menus/ProductModal";
import ModifierModal from "@/components/Menus/ModifierModal.vue";
import { api } from "@/helpers/api";
import Slug from "slug";

export default {
  name: "SiteMenu",
  mixins: [createdMixin],
  components: { Authenticated, ProductModal, ModifierModal },
  data() {
    return {
      modifierTab: false,
      productTab: true,
      site: this.$route.params.site,
      tab: "",
      categories: [],
      product: {},
      showModal: false,
      showModifierModal: false,
      company: localStorage.getItem("company"),
      modifiersets: false,
      channel: false,
      selectedModifier: {},
      newModSet: false,
      newCategory: false,
      newCategoryName: "",
      newModSetName: "",
      deleteCheck: false,
      current: {},
      type: "",
      idx: "",
      labelInput: {
        value: "",
        label: "",
      },
    };
  },

  async mounted() {
    const savedTab = localStorage.getItem("activeTab"); // Retrieve the saved tab
    if (savedTab === "modifiers") {
      this.showModifierTab();
    } else {
      this.showProductTab(); // Default to Products tab if nothing is saved
    }

    this.modifiersets = await api.getModifierSets(this.company, this.site);
    console.log("mounted", this.modifiersets);

    this.channels.map((channel) => {
      let topic = channel.topic.split(":");
      if (topic[0] == "pos" && topic[2] == this.site) {
        this.channel = channel;
      }
    });
  },

  computed: {
    ...mapGetters({
      POSProducts: "POSProducts",
      POSCategories: "POSCategories",
      POSProductLookup: "POSProductLookup",
      channels: "channels",
    }),
  },
  methods: {
    add(type) {
      if (type == "modifierSet") {
        this.labelInput = {
          value: "mod",
          label: "Modifier Set Name",
        };
        this.newModSet = true;
        return;
      }
      if (type == "category") {
        this.labelInput = {
          value: "cat",
          label: "Category Name",
        };
        this.newCategory = true;
        return;
      }
    },

    async removeCategory() {
      console.log("deleting:", this.current);
      /* step two remove it from modifier_sets */
      await this.channel.push("document:delete", {
        doc: this.current.key._id,
        rev: this.current.key._rev,
      });
      this.$store.dispatch("getPOSCategories");
      this.deleteCheck = false;
    },

    async addCategory() {
      let cat = {
        _id: "category:" + Slug(this.newCategoryName),
        name: this.newCategoryName,
        description: "",
        posOnly: false,
        products: [],
      };
      console.log(cat);
      this.newCategory = false;
      await this.channel.push("document:new", cat);
      this.$store.dispatch("getPOSCategories");
    },

    check(idx, current, type) {
      this.deleteCheck = !this.deleteCheck;
      this.type = type;
      this.idx = idx;
      this.current = current;
    },

    confirmDelete(type) {
      if (type == "modifier") {
        this.removeModifierSet();
      }
      // this.confirmDelete = false;
      if (type == "category") {
        this.removeCategory();
      }
    },

    async removeModifierSet() {
      console.log("deleting:", this.current);

      await this.channel.push("document:delete", {
        doc: this.current.value._id,
        rev: this.current.value._rev,
      });
      this.modifiersets = await api.getModifierSets(this.company, this.site);
      this.deleteCheck = false;
      console.log(this.modifiersets);
    },

    async addNewModifierSet() {
      let modSet = {
        _id: 'modifierset:' + Slug(this.newModSetName),
        name: this.newModSetName,
        modifiers: [],
        rules: { inventory: false, oneonly: false, required: false}
      };
      this.channel.push("document:new", modSet);
      this.modifiersets = await api.getModifierSets(this.company, this.site);
      this.newModSet = false;
    },

    openModifierModal(modifier) {
      console.log(this.selectedModifier);
      if (modifier == "addNew") {
        this.selectedModifier = {
          active: false,
          desc: "",
          name: "",
          price: 0,
          quantity: 0,
        };
        this.showModifierModal = !this.showModifierModal;
        return;
      } else {
        this.selectedModifier = modifier;
        this.showModifierModal = !this.showModifierModal;
      }
    },

    showModifierTab() {
      this.modifierTab = true;
      this.productTab = false;
      this.tab = "modifiers";
      localStorage.setItem("activeTab", "modifiers");

      document.querySelectorAll(".tab-btn").forEach((btn) => {
        btn.classList.remove("active"); // Clear the active class from all buttons
      });

      //keeps focus on the tab button
      this.$nextTick(() => {
        let mod_btn = document.querySelector('.tab-btn[data-tab="modifiers"]');
        mod_btn.classList.add("active");
      });
    },

    showProductTab() {
      this.productTab = true;
      this.modifierTab = false;
      this.tab = "products";
      localStorage.setItem("activeTab", "products");

      document.querySelectorAll(".tab-btn").forEach((btn) => {
        btn.classList.remove("active"); // Clear the active class from all buttons
      });

      //keeps focus on the tab button
      this.$nextTick(() => {
        let prod_btn = document.querySelector('.tab-btn[data-tab="products"]');
        prod_btn.classList.add("active");
      });
    },

    save() {
      this.channel.push("document:update", this.current);
      //console.log(this.current);
    },

    async removeProduct(product) {
      var foundProductIndex = this.current.products.findIndex((p) => {
        return "product:" + p === product._id;
      });

      if (foundProductIndex != -1) {
        await this.channel.push("document:delete", {
          doc: product._id,
          rev: product._rev,
          category: Slug(this.name),
        });
        this.current.products.splice(foundProductIndex, 1);
        this.save();
      }
    },

    saveProduct(product) {
      console.log(product.name);
      var foundProduct = this.POSProductLookup({
        site: this.site,
        prod: Slug(product.name),
      });
      ////console.log(product.name);
      if (foundProduct) {
        console.log(product);
        foundProduct = product;
        this.channel.push("document:update", product);
      } else {
        product._id = "product:" + Slug(product.name);
        product.category = Slug(this.name);
        this.channel.push("document:new", product);
        ////console.log(product);
        this.current.products.push(Slug(product.name));
        this.save();
      }
    },

    openModal(product) {
      if (product == "addNew") {
        this.product = {
          value: {
            name: null,
            description: null,
            caption: null,
            active: true,
            price: null,
            modifiersets: [],
            images: [],
            soldout: false,
          },
        };
        this.showModal = !this.showModal;
        return;
      } else {
        this.showModal = !this.showModal;
        this.product = this.POSProductLookup({
          site: this.site,
          prod: product,
        });
      }
    },
  },

  watch: {
    tab: function(newTab) {
      this.$router.replace({
        name: "site-menu-tab",
        params: { site: this.site, tab: newTab },
      });
    },
  },
};
</script>

<style scoped>
.none {
  display: none;
}

.check-container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: var(--background-fade);
}
.check-content {
  position: relative;
  top: 30%;
  left: 30%;
  height: 30vh;
  width: 40vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1rem;
  z-index: 100;
  border-radius: 10px;
  box-shadow: var(--box-shadow);
  background-color: var(--secondary-colour);
  .set-name {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 1rem;
    width: 90%;
    input {
      margin: 0.5rem;
      padding: 0.5rem;
      border-radius: 10px;
      border: none;
      box-shadow: var(--box-shadow);
      width: 90%;
    }
    .btns {
      display: flex;
      flex-direction: row;
      button {
        margin: 0.5rem;
        padding: 1rem 2rem;
        border-radius: 10px;
        box-shadow: var(--box-shadow);
        background-color: var(--action-colour);
        color: var(--text-colour);
        &:hover {
          cursor: pointer;
          background-color: var(--action-colour);
        }
      }
    }
  }
}

.section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  overflow-y: scroll;
  height: 100%;
}

h2 {
  margin: 1rem 0.5rem 0.5rem 1rem;
  text-transform: capitalize;
}

.product-grid-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 0.5rem auto;
  width: 100%;
  .bin {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    svg {
      /* margin: 0 0.5rem; */
      color: var(--action-colour);
      cursor: pointer;
    }
  }
}

.product-grid {
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: repeat(6, 1fr);
  gap: 0.5rem;
  margin: 0.5rem;
}
.product {
  height: 5rem;
  width: 10rem;
  border-radius: 5px;
  padding: 0.5rem;
  background-color: var(--secondary-colour);
  box-shadow: var(--box-shadow);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  cursor: pointer;
  p {
    margin: 0.25rem;
    color: var(--text-colour);
  }
  &:active {
    box-shadow: none;
  }
}

.tab-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-colour);
  margin: 0 2rem 2rem 2rem;
  border-radius: 0 20px 20px 20px;
  z-index: 1;
  box-shadow: var(--box-shadow);
}

.tab-buttons {
  display: flex;
  flex-direction: row;
  margin: 0 0 0 2rem;
}
.tab-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10rem;
  height: 2.5rem;
  margin: 0.5rem 0 0 0;
  padding: 1rem;
  background-color: var(--primary-colour);
  opacity: 0.5;
  color: var(--text-colour);
  border-radius: 10px 10px 0 0;
  text-transform: capitalize;
  transition: color 300ms ease-in-out;
  box-shadow: none;
  transition: all 300ms ease-in-out;
  &:hover {
    cursor: pointer;
    color: var(--action-colour);
  }
}

.tab-btn.active {
  /* z-index: 10; */
  box-shadow: 0px -5px 5px rgb(0, 0, 0, 0.2);
  transform: translate3d(0, 5px, 1px);
  opacity: 1;
}

@media only screen and (orientation: portrait) {
  h3,
  h2 {
    width: 50%;
  }

  .product-grid {
    width: 50%;
    grid-template-columns: repeat(2, 0fr);
    grid-auto-flow: row;
    margin: 0.5rem;
  }

  .product {
    width: 8rem;
    height: 3.5rem;
  }
}
</style>
