<template>
  <Authenticated>
    <div class="header">
      <h2>Settings</h2>
    </div>

    <div class="payment_options">
      <form @submit.prevent>
        <h3>Company Details</h3>
        <header class="header">
          <span>
            <input
              type="text"
              :value="companySettings.company_name"
              id="name"
              class="detail"
              placeholder="Legal Entity"
              @click.stop.prevent="this.editName = !this.editName"
            />
            <font-awesome-icon
              v-if="editName"
              icon="floppy-disk"
              class="edit blue"
              @click.stop.prevent="saveName"
            />
          </span>
          <span>
            <input
              type="text"
              :value="companySettings.gst"
              id="gst"
              class="detail"
              placeholder="GST number"
              @click.stop.prevent="this.editGst = !this.editGst"
            />
            <font-awesome-icon
              v-if="editGst"
              icon="floppy-disk"
              class="edit blue"
              @click.stop.prevent="saveGst"
            />
          </span>
        </header>
        <section class="section">
          <h3>Trading Details</h3>
          <ul class="grid wrapper">
            <li class="modifierBox">
              <label for="cogs_enable">Enable COGS</label>
              <ToggleSwitch :switchId="'switch-' + s" :toggle="toggleObject()" @toggle-action="enableCogs" />
            </li>
            <li class="modifierBox">
              <label for="timeZone">Time Zone:</label>
              <div @click.stop.prevent="openTZModal">
                <p class="blue">{{ timezone }}</p>
              </div>
            </li>
            <li class="modifierBox">
              <label for="currency">Currency</label>
            </li>
          </ul>
        </section>

        <section class="section">
          <div class="add-site">
            <h3>
              Sites
              <font-awesome-icon
                @click.stop.prevent="createSite = !createSite"
                icon="plus"
                class="add"
              />
            </h3>
            <span v-if="createSite">
              <input type="text" v-model="site" />
              <font-awesome-icon
                icon="floppy-disk"
                class="edit blue"
                @click.stop.prevent="addSite"
              />
            </span>
          </div>
          <div class="sites">
            <details v-for="(site, idx) in siteMap" :key="site + idx">
              <summary @click="dropDown(idx)">
                <h3>
                  <font-awesome-icon
                    icon="caret-right"
                    class="edit blue"
                    :class="{ rotated: activeIndex === idx }"
                  />
                  {{ site.site }}
                </h3>
                <div>
                  <p v-if="site.stations.kds.length">
                    KDS: {{ site.stations.kds.length }}
                  </p>
                  <p v-else>KDS: 0</p>
                  <p v-if="site.stations.pos.length">
                    POS: {{ site.stations.pos.length }}
                  </p>
                  <p v-else>POS: 0</p>
                </div>
              </summary>
              <h3>Direct POS Settings (Site Wide)</h3>
              <DirectSettings class="grid wrapper" :site="site.site" />
              <h3>POS</h3>
              <ul class="grid wrapper">
                <li
                  v-for="(station, idx) in site.stations.pos"
                  :key="station + idx"
                  class="modifierBox station"
                  @click="openPOSModal(station)"
                >
                  {{ station.name
                  }}<font-awesome-icon
                    icon="wifi"
                    :class="(station.active ? 'active' : 'discon') + ' icon'"
                  ></font-awesome-icon>
                </li>
                <li
                  class="modifierBox"
                  @click.prevent.self="openPOSModal(site.site, true)"
                >
                  Add new station
                </li>
              </ul>

              <h3>KDS</h3>
              <ul class="grid wrapper">
                <li
                  v-for="(station, idx) in site.stations.kds"
                  :key="station + idx"
                  class="modifierBox station"
                  @click="openKDSModal(station)"
                >
                  {{ station.name
                  }}<font-awesome-icon
                    icon="wifi"
                    :class="(station.active ? 'active' : 'discon') + ' icon'"
                  ></font-awesome-icon>
                </li>
                <li
                  class="modifierBox"
                  @click.prevent.self="openKDSModal(site.site, true)"
                >
                  Add new station
                </li>
              </ul>

              <h3>Companion</h3>
              <ul class="grid wrapper">
                <li
                  v-for="(station, idx) in site.stations.pos"
                  :key="station + idx"
                  class="modifierBox station"
                  @click="openCompanionModal(station)"
                >
                  {{ station.name
                  }}<font-awesome-icon
                    icon="link"
                    :class="(station.active ? 'active' : 'discon') + ' icon'"
                  ></font-awesome-icon>
                </li>
              </ul>
            </details>
          </div>
        </section>

        <section class="section">
          <h3>Display Theme</h3>
          <Theme />
        </section>
      </form>
    </div>
    <KDSStationModal
      :open="showKDSModal"
      :station="KDSStation"
      :newStation="newStation"
      @update="updateStations"
    />
    <POSStationModal
      :open="showPOSModal"
      :station="POSStation"
      :newStation="newStation"
      @update="updateStations"
    />
    <CompanionModal :open="showCompanionModal" :station="companionStation" />
    <TZModal :open="showTZModal" />
  </Authenticated>
</template>

<script>
// import Discounts from '@/components/Discounts/Discounts.vue'
import Authenticated from "@/components/_layouts/Authenticated";
import createdMixin from "@/components/_mixins/createdMixin";
import KDSStationModal from "./KDSStationModal.vue";
import POSStationModal from "./POSStationModal.vue";
import CompanionModal from "./CompanionModal.vue";
import TZModal from "./TZModal.vue";
import { api } from "@/helpers/api";
import { mapGetters } from "vuex";
import Theme from "./Theme.vue";
import DirectSettings from "./DirectSettings.vue";
import ToggleSwitch from "@/components/_layouts/ToggleSwitch.vue";
//import dayjs from "dayjs";

export default {
  name: "Modifiers",
  mixins: [createdMixin],
  components: {
    Authenticated,
    Theme,
    KDSStationModal,
    POSStationModal,
    CompanionModal,
    TZModal,
    ToggleSwitch,
    DirectSettings,
  },

  // setup: {
  //   provide("station", this.station),
  // },

  data() {
    return {
      kds_enable: false,
      pos_enable: false,
      cogs_enable: this.cogsEnableCheck,
      showKDSModal: false,
      showPOSModal: false,
      showTZModal: false,
      showCompanionModal: false,
      POSStation: null,
      companionStation: null,
      KDSStation: null,
      newStation: false,
      settings: null,
      station: "",
      timer: null,
      editGst: false,
      editName: false,
      site: "",
      createSite: false,
      activeIndex: null,
    };
  },

  computed: {
    ...mapGetters({
      channels: "channels",
      siteMap: "siteMap",
      siteSettings: "siteSettings",
      companySettings: "companySettings",
      timezone: "timezone",
      sites: "sites",
    }),

    cogsEnableCheck() {
      return this.companySettings.cogs_enable;
    }
  },

  methods: {
    dropDown(idx) {
      this.activeIndex = this.activeIndex === idx ? null : idx;
    },

    toggleObject() {
      return { contains: this.companySettings.cogs_enable, name: "cogs_enable" };
    },



    addSite() {
      api.add_site(this.site, this.companySettings.company_slug);
      this.site = "";
      this.createSite = false;
      this.$store.dispatch("getAllData");
    },

    saveGst() {
      let gst = document.getElementById("gst").value;
      api.save_company_settings(this.companySettings.company_slug, "gst", gst);
      this.editGst = !this.editGst;
      this.$store.dispatch("getSettings");
    },

    saveName() {
      let name = document.getElementById("name").value;
      api.save_company_settings(
        this.companySettings.company_slug,
        "company_name",
        name
      );
      this.editName = !this.editName;
      this.$store.dispatch("getSettings");
    },

    openTZModal() {
      this.showTZModal = !this.showTZModal;
    },

    openCompanionModal(station) {
      this.companionStation = station;
      this.showCompanionModal = !this.showCompanionModal;
    },

    openKDSModal(incoming, newStation = false) {
      this.KDSStation = {
        name: null,
        site: null,
        categories: [],
      };
      this.newStation = newStation;
      if (newStation) {
        this.KDSStation.site = incoming;
        this.showKDSModal = !this.showKDSModal;
        return;
      }
      this.KDSStation = incoming;
      this.showKDSModal = !this.showKDSModal;
    },
    openPOSModal(incoming, newStation = false) {
      this.POSStation = {
        name: null,
        site: null,
        eftpos: {
          ip: null,
          port: null,
        },
      };
      this.newStation = newStation;
      if (newStation) {
        this.POSStation.site = incoming;
        this.showPOSModal = !this.showPOSModal;
        return;
      }
      this.POSStation = incoming;
      this.showPOSModal = !this.showPOSModal;
    },
    async updateStations(typeOfUpdate, typeOfStation, incomingStation) {
      let newStations = [];

      this.siteMap.forEach((site) => {
        if (site.site == incomingStation.site) {
          site.stations[typeOfStation].forEach((station) => {
            if (typeOfUpdate === "update") {
              if (station.name == incomingStation.name) {
                newStations.push(incomingStation);
              } else {
                newStations.push(station);
              }
            } else if (typeOfUpdate === "delete") {
              if (station.name != incomingStation.name) {
                newStations.push(station);
              }
            }
          });
        } else {
          site.stations[typeOfStation].forEach((station) => {
            newStations.push(station);
          });
        }
      });
      this.showKDSModal = false;
      this.showPOSModal = false;

      await api.post(
        "/api/company/update_stations/" + this.$store.getters.companyName,
        { doc: newStations, station_type: `get_${typeOfStation}` }
      );
      this.$store.dispatch("initialSiteMap");
    },
  },
};
</script>
<style src="@vueform/toggle/themes/default.css"></style>
<style lang="scss">
@import "public/stylesheets/layout";
@import "public/stylesheets/wrapper";
@import "public/stylesheets/actions";

.header {
  padding: 2em 0 0 0;
}

form {
  label {
    display: contents;
  }
  .blue {
    color: var(--action-colour);
    cursor: pointer;
  }

  .add {
    color: var(--action-colour);
    cursor: pointer;
    margin-left: 1rem;
  }

  .sites {
    display: flex;
    flex-direction: column;
    background: var(--background-fade);
    box-shadow: var(--box-shadow);
    border-radius: 10px;
    margin: 1rem;
    padding: 1rem;
  }

  .add-site {
    display: flex;
    flex-direction: row;
    align-items: center;
    h3 {
      margin: 0.25rem 0 0.25rem 1rem;
    }
    span {
      display: inherit;
      margin: 0 0.5rem;
      align-items: center;
      input {
        background-color: var(--primary-colour);
        box-shadow: var(--box-shadow);
        border: 1px solid var(--action-colour);
        border-radius: 5px;
        font-size: 1.25rem;
        color: var(--text-colour);
        margin: 0 0.5rem;
        padding: 0.25rem;
      }
      button {
        background-color: var(--action-colour);
        color: var(--secondary-text-colour);
        padding: 0.5rem;
        border-radius: 5px;
        margin: 0 0.5rem;
      }
    }
  }

  .header {
    display: flex;
    flex-direction: column;
    background: var(--background-fade);
    box-shadow: var(--box-shadow);
    border-radius: 10px;
    margin: 1rem;
    padding: 1rem;
    h4 {
      display: inline-block;
      margin: 1rem;
    }
    .detail {
      width: 50%;
      background-color: var(--primary-colour);
      box-shadow: var(--box-shadow);

      border: none;
      border-radius: 5px;
      font-size: 1.25rem;
      color: var(--text-colour);
      padding: 0.5rem;
      margin: 0.5rem;
    }
    .text {
      font-size: 1.25rem;
      color: var(--text-colour);
      background: none;
      border: none;
      border-radius: none;
      box-shadow: none;
      padding: 0.5rem;
      margin: 0.5rem;
    }
    .edit {
      cursor: pointer;
      margin: 0 5px;
    }
  }
}
.station {
  display: flex;
  flex-direction: row !important;
  justify-content: space-between !important;
}

details > summary {
  list-style: none;
}
details > summary::-webkit-details-marker {
  display: none;
}

details {
  margin: 0;
  list-style: none;
  summary {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .rotated {
      transform: rotate(90deg);
      transition: all 500ms ease-in-out;
    }
    div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      p {
        margin: 0.25rem 1rem;
        font-size: x-small;
        color: var(--action-colour);
      }
    }
    h3 {
      text-transform: capitalize;
      margin: 0.5rem 0;
    }
    box-shadow: var(--box-shadow);
    background-color: var(--primary-colour);
    padding: 0.75rem;
    border-radius: 5px;
    margin: 0.5rem;
    cursor: pointer;
    &:active {
      transition: all 2000ms ease-in-out;
    }
  }
}

details summary > * {
  display: inline;
}

.active {
  color: var(--action-colour) !important;
}
.discon {
  color: var(--warning-colour) !important;
}

.icon {
  font-size: 1.5rem;
}

@media only screen and (orientation: portrait) {
  div.actions {
    padding: 3rem 1rem;
  }
  .payment-options {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    form {
      .header {
        width: 90%;
      }
      .section {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 90%;
      }
    }
  }
}
</style>
