<template>
  <Authenticated>
    <h1>Menu Manager</h1>

    <div class="menu-container">
      <section class="section">
        <h2>Add menu</h2>
        <p>Accepted file types jpeg/jpg, png, pdf, docx, doc</p>
        <form action="">
          <input type="file" @change="handleFile" accept="image/*, .jpeg, .jpg, .png,  application/*, .pdf, .docx, .doc" />
          <button @click.stop.prevent="uploadFile">Upload</button>
        </form>
      </section>
      <section class="section">
        <h2>Current Menus</h2>
        <div v-for="site in sites" :key="site">
          <RouterLink :to="`/menus/${site}`" class="site-btn" tabindex="0">{{ site }}</RouterLink>
        </div>
      </section>
    </div>
  </Authenticated>
</template>

<script>
import Authenticated from "../_layouts/Authenticated";
import createdMixin from "@/components/_mixins/createdMixin";
import { mapGetters } from "vuex";
export default {
  name: "Menus",
  mixins: [createdMixin],
  components: { Authenticated },
  data() {
    return {
      file: null,
      erros: null,
    };
  },
  computed: {
    ...mapGetters({
      sites: "sites",
    }),
  },

  methods: {
    handleFile(event) {
      let fileName = event.target.files[0].name;
      let file = event.target.files;
      if (fileName.includes(".jpeg") || fileName.includes(".jpg") || fileName.includes(".png")) {
        console.log("Image file");
      } else if (fileName.includes(".pdf")) {
        console.log("PDF file");
      } else if (fileName.includes(".docx") || fileName.includes(".doc")) {
        console.log("Word file");
      } else {
        console.log("Invalid file type");
      }
      console.log(file);
    },
    uploadFile() {
      console.log("uploadFile");
    },
  },
};
</script>

<style scoped>
.none {
  display: none;
}

h1 {
  margin: 3rem 1rem 1rem 1rem;
}

.site-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20rem;
  height: 2.5rem;
  margin: 0.5rem;
  padding: 1rem;
  background-color: var(--secondary-colour);
  color: var(--text-colour);
  border-radius: 10px;
  box-shadow: var(--box-shadow);
  text-transform: capitalize;
  transition: color 0.3s ease-in-out;
  &:active {
    box-shadow: none;
  }
  &:hover {
    cursor: pointer;
    color: var(--action-colour);
  }
}

.menu-container {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  overflow: scroll;
  .section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: auto;
    border-radius: 10px;
    background-color: var(--primary-colour);
    box-shadow: var(--box-shadow);
    padding: 1rem;
    margin: 1rem;
    form {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      input {
        margin: 1rem;
        width: 20rem;
        height: 10rem;
        border: dotted 2px var(--action-colour);
        border-radius: 5px;
      }
      button {
        width: 20rem;
        margin: 1rem;
        background-color: var(--action-colour);
        color: var(--secondary-text-colour);
      }
    }
  }
}

@media only screen and (orientation: portrait) {
  .menu-container {
    flex-direction: column;
    padding: 0.5rem;
    .section {
      width: 90%;
      margin: 0.15rem;
      .site-btn {
        width: 15rem;
      }
      form {
        input {
          width: 15rem;
          height: 10rem;
        }
        button {
          width: 15rem;
        }
      }
    }
  }
}
</style>
