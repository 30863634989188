<template>
  <div class="allergens">
    <section class="allergen-list">
      <p>Contains:&nbsp;</p>
      <p v-for="allergen in currentAllergens" :key="allergen">{{ allergen.name }},&nbsp;</p>
    </section>
    <details>
      <summary>Add allergen</summary>
      <section class="grid">
        <div v-for="(allergen, idx) in existingAllergens" :key="idx" class="container">
          <label for="allergen">{{ allergen.name }}</label>
          <ToggleSwitch :switchId="'switch-' + idx" :toggle="allergen" @toggle-action="emitAllergen" />
        </div>
      </section>
    </details>
  </div>
</template>

<script>
// import Toggle from "@vueform/toggle";
import ToggleSwitch from "@/components/_layouts/ToggleSwitch.vue";

export default {
  name: "AllergenList",
  components: {
    ToggleSwitch,
  },
  props: {
    allergens: {
      type: Array,
      required: false,
    },
  },
  emits: ["allergenChanged"],
  data() {
    return {
      addAllergens: [
        { name: "Celery", contains: false },
        { name: "Gluten", contains: false },
        { name: "Crustacean", contains: false },
        { name: "Egg", contains: false },
        { name: "Fish", contains: false },
        { name: "Lupin", contains: false },
        { name: "Milk", contains: false },
        { name: "Mollusc", contains: false },
        { name: "Mustard", contains: false },
        { name: "Tree Nut", contains: false },
        { name: "Peanut", contains: false },
        { name: "Sesame Seed", contains: false },
        { name: "Soya", contains: false },
        { name: "Sulphur Dioxide", contains: false },
      ],
    };
  },
  methods: {
    emitAllergen(allergen, contains) {
      this.existingAllergens.forEach((all) => {
        if (all.name === allergen) {
          all.contains = contains;
        }
      });
      return this.$emit("allergenChanged", this.existingAllergens);
    },
  },
  computed: {
    currentAllergens() {
      let removedAllergen = this.existingAllergens.filter((allergen) => allergen.contains);
      return removedAllergen;
    },

    existingAllergens() {
      if (this.allergens) {
        return this.allergens;
      } else {
        return this.addAllergens;
      }
    }
  },
  mounted() {
    // Lifecycle hook when the component is mounted
  },
};
</script>

<style scoped>
.allergen-list {
  display: flex;
  flex-direction: column;
}

.allergen-list {
  display: flex;
  flex-direction: row;
}

summary {
  margin: 0.5rem 0;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-template-rows: repeat(auto-fill, minmax(100px, 1fr));
  gap: 0.5rem;
  background-color: var(--primary-colour);
  border-radius: 10px;
  padding: 0.25rem;
  margin: 0.5rem;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0.5rem;
  background-color: var(--secondary-colour);
  box-shadow: var(--box-shadow);
  border-radius: 10px;
  padding: 0.5rem;
}


@media only screen and (orientation: portrait) {
  .grid {
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    grid-auto-flow: row;
    padding: 1rem;
    justify-items: center;
  }

  .container {
    width: 90%;
  }
}
</style>
