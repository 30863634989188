<template>
  <teleport to="body">
    <div v-if="showModal" class="modal">
      <div class="product">
        <div class="wrapper">
          <div class="content">
            <font-awesome-icon
              class="exit"
              icon="fa-xmark"
              @click="closeModal"
            />
            <div class="title">
              <h3>Create/Edit Product: {{ currentProduct.name }}</h3>
            </div>

            <label v-if="!currentProduct._id">Name</label>
            <input
              v-if="!currentProduct._id"
              type="text"
              v-model="currentProduct.name"
            />
            <label>Active / Available Product</label>
            <Toggle
              class="toggle"
              onLabel="Available"
              offLabel="Unavailable"
              v-model="currentProduct.active"
            />
            <label>Sold Out</label>
            <Toggle
              class="toggle"
              onLabel="Sold Out"
              offLabel="Available"
              v-model="currentProduct.soldout"
            />

            <label>Caption</label>
            <input
              type="html"
              v-model="currentProduct.caption"
              class="radius"
            />

            <label>Description</label>
            <textarea
              type="html"
              v-model="currentProduct.description"
              rows="5"
              columns="10"
              class="radius"
            ></textarea>

            <label>Price</label>
            <input
              type="text"
              v-model.number="currentProduct.price"
              placeholder="123"
              class="radius"
            />

            <div>
              <AllergenList
                @allergen-changed="changeAllergens"
                :allergens="currentProduct.allergens"
              />
            </div>

            <div class="drop-content">
              <div>
                <label>Images</label>
                <span contenteditable
                  ><input
                    class="dropzone"
                    type="file"
                    id="files"
                    name="files[]"
                    @change="handleFileSelect"
                    multiple
                    accept=".jpg, .jpeg, .png"
                /></span>
              </div>
              <div>
                <label>Training Video</label>
                <span contenteditable
                  ><input
                    type="file"
                    multiple
                    accept="video/*, "
                    capture="environment"
                    @change.stop.prevent="handleVideoSelect"
                    class="dropzone"
                /></span>
              </div>
            </div>
            <label
              >Camera
              <!-- <font-awesome-icon icon="camera" class="camera-icon" /> -->
              <input
                type="file"
                accept="image/*"
                capture="environment"
                class="camera"
                @change="handleFileSelect"
              />
            </label>
            <canvas id="preview"></canvas>
            <div class="image-display">
              <vueDraggable
                :list="imgList"
                :disabled="!enabled"
                item-key="id"
                class="image-display"
                @start="drag = true"
                @end="drag = false"
                :move="checkMove"
              >
                <template #item="{ element }">
                  <div
                    class="list-group-item"
                    :class="{ 'not-draggable': !enabled }"
                  >
                    <img :src="element.src" />
                    <font-awesome-icon
                      icon="fa-solid fa-trash"
                      class="bin-icon"
                      @click="deleteImg(element.id)"
                    />
                  </div>
                </template>
              </vueDraggable>
            </div>

            <label>Modifier Sets</label>
            <ul class="modifiersets">
              <li v-for="modifierSet in modifierSets" :key="modifierSet">
                <button
                  @click="selectModifierSet(modifierSet)"
                  :class="
                    currentProduct &&
                    !!currentProduct.modifiersets.find(
                      (ms) => ms === modifierSet.id
                    )
                      ? 'selected'
                      : 'unselected'
                  "
                >
                  {{ modifierSet.value.name }}
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div class="overlay">
          <div class="actions footer">
            <button class="action danger" @click.prevent.stop="remove">
              Delete
            </button>
            <button
              class="action cancel cancel-text"
              @click.prevent.stop="cancel"
            >
              Cancel
            </button>
            <button class="action primary" @click.prevent.stop="commit">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import vueDraggable from "vuedraggable";
import Toggle from "@vueform/toggle";
import { mapGetters } from "vuex";
import { api } from "../../helpers/api";
import AllergenList from "./AllergenList.vue";
// import LoadingSpinner from "@/components/_layouts/LoadingSpinner";

export default {
  name: "ProductModal",
  props: ["open", "product", "modifierSets"],
  emits: ["save", "remove", "newItem", "clone", "closeModal"],
  components: {
    vueDraggable,
    Toggle,
    AllergenList,
  },
  data() {
    return {
      showModal: false,
      imgSrc: null,
      imageBase64: null,
      editor: null,
      upHere: false,
      startPos: 0,
      drag: false,
      imgList: [],
      enabled: true,
      videoLoading: false,
      currentProduct: {},
    };
  },

  computed: {
    ...mapGetters({
      company: "company",
    }),
  },

  methods: {
    checkMove: function(e) {
      const fromIndex = e.draggedContext.index;
      const element = this.currentProduct["images"].splice(fromIndex, 1)[0];
      this.currentProduct["images"].splice(
        e.draggedContext.futureIndex,
        0,
        element
      );
      this.$emit("save", this.currentProduct);
    },
    deleteImg: function(position) {
      //console.log(position);
      this.currentProduct["images"].splice(position, 1);
      this.$emit("save", this.currentProduct);
      this.imgList = this.currentProduct.images.map((src, index) => ({
        id: index,
        src: src,
      }));
    },
    remove() {
      this.$emit("remove", this.currentProduct);
      this.$emit("closeModal");
      this.showModal = false;
    },
    cancel() {
      this.closeModal();
    },
    commit() {
      this.$emit("save", this.currentProduct);
      this.$emit("closeModal");
      this.showModal = false;
    },
    changeAllergens(allergens) {
      this.currentProduct["allergens"] = allergens;
    },

    closeModal() {
      this.showModal = !this.showModal;
      this.currentProduct = {};
      this.$emit("closeModal");
    },

    /*
    clone() {
      console.log(this.currentProduct);
      this.$emit("clone", this.currentProduct);
      this.open = false;
    },
    */

    async handleVideoSelect(event) {
      console.log(event);
      let files = event.target.files;
      this.videoLoading = true;
      for (let index = 0; index < files.length; index++) {
        let response = await api.videoUpload(
          "/api/video/upload/" + this.company + "/" + this.currentProduct._id,
          files[index]
        );
        this.currentProduct["video"] = response.file;
      }
      this.videoLoading = false;
    },

    handleFileSelect(event) {
      console.log(event);
      let newImageURL = "";
      let files = event.target.files;
      for (let index = 0; index < files.length; index++) {
        const imgFile = files[index];
        const WIDTH = 200;
        var reader = new FileReader();
        reader.readAsDataURL(imgFile);
        reader.onload = (event) => {
          let imgURL = event.target.result;
          let image = document.createElement("img");
          image.src = imgURL;

          image.onload = () => {
            var canvas = document.getElementById("preview");
            //let ratio = WIDTH / e.target.width;
            canvas.width = WIDTH;
            canvas.height = WIDTH;

            // get the scale
            // it is the min of the 2 ratios
            let scaleFactor = Math.max(
              canvas.width / image.width,
              canvas.height / image.height
            );

            // Finding the new width and height based on the scale factor
            let newWidth = image.width * scaleFactor;
            let newHeight = image.height * scaleFactor;

            // get the top left position of the image
            // in order to center the image within the canvas
            let x = canvas.width / 2 - newWidth / 2;
            let y = canvas.height / 2 - newHeight / 2;
            var ctx = canvas.getContext("2d");

            // When drawing the image, we have to scale down the image
            // width and height in order to fit within the canvas
            ctx.drawImage(image, x, y, newWidth, newHeight);

            newImageURL = ctx.canvas.toDataURL("image/jpeg", 90);
            this.currentProduct["images"].push(newImageURL);
            this.imgList = this.currentProduct.images.map((src, index) => ({
              id: index,
              src: src,
            }));
          };
        };
      }
    },
    selectModifierSet(modifierSet) {
      if (
        this.currentProduct.modifiersets.find((ms) => ms === modifierSet.id)
      ) {
        this.currentProduct.modifiersets = this.currentProduct.modifiersets.filter(
          (ms) => ms !== modifierSet.id
        );
      } else {
        this.currentProduct.modifiersets.push(modifierSet.id);
      }
      console.log(this.currentProduct.modifiersets);
    },
    deleteImage(position) {
      this.currentProduct["images"].splice(position, 1);
    },
    handleDragStart(e) {
      this.startPos = e.target.__vnode.key;
      e.target.style.opacity = "0.4";
    },
    handleDrag(e) {
      e.target.style.opacity = "0.4";
    },
    handleDragEnd(e) {
      e.target.style.opacity = "1";
    },
    handleDrop(e) {
      this.handleSwitch(e.target.__vnode.key);
    },
    handleSwitch(endIndex) {
      const fromIndex = this.startPos;
      const element = this.currentProduct["images"].splice(fromIndex, 1)[0];
      this.currentProduct["images"].splice(endIndex, 0, element);
      this.$emit("save", this.currentProduct);
    },
    handleDelete(e) {
      e.preventDefault();
      confirm("Are you sure you want to delete this image?")
        ? this.currentProduct["images"].splice(this.startPos, 1) &&
          this.handleDragLeave(e)
        : this.handleDragLeave(e);
    },
    handleDragOver(e) {
      e.preventDefault();
      e.target.style.backgroundColor = "var(--warning-colour)";
    },
    handleDragLeave(e) {
      e.target.style.backgroundColor = "#aaa";
    },
  },
  watch: {
    async open() {
      this.showModal = true;
    },

    product() {
      this.currentProduct = this.product;
      if ("images" in this.currentProduct) {
        this.imgList = this.currentProduct.images.map((src, index) => ({
          id: index,
          src: src,
        }));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.cancel-text {
  color: var(--text-colour);
  background-color: var(--primary-colour);
}

.modal {
  .svg-spinner {
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 10;
    position: absolute;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    .loading-spinner {
      z-index: 100;
      position: absolute;
    }
  }
  .wrapper {
    padding-bottom: 5rem;
  }
  display: flex;
  position: fixed;
  div.content {
    display: flex;
    flex: 1;
    overflow-y: auto;
    .drop-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      div {
        flex: 1;
        margin-right: 1rem;
      }
    }
    input {
      border-radius: 5px;
    }
    summary {
      border-radius: 10px;
    }
    .radius {
      border-radius: 5px;
      box-shadow: var(--box-shadow);
      border: none;
      padding: 0.25rem;
    }
    .dropzone {
      width: 400px;
      height: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px dotted #ccc !important;
      border-radius: 5px;
    }
    .modifiersets {
      flex: 1 1;
      list-style: none;
      margin: 0px;
      margin-right: 1rem;
      padding: 0px;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
      grid-auto-flow: row;
      grid-column-gap: 1rem;
      grid-row-gap: 1rem;
      //flex-wrap: wrap;
      //justify-content: stretch;
      .unselected {
        color: var(--text-colour);
      }

      li button {
        position: relative;
        cursor: pointer;
        flex: 1 1;
        padding: 1rem;
        width: 100%;
        min-height: 70px;
        margin: 0.2rem;
        background-color: var(--primary-colour);
        border-color: var(--hover-colour);
        border-style: none;

        &.selected {
          background-color: var(--action-colour);
        }
      }
    }
  }

  .overlay {
    display: flex;
    flex-direction: column;
    flex: 1;
    position: absolute;
    bottom: 2rem;
    min-width: 80vw;
    max-width: 90vw;

    .footer {
      position: relative;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 1rem;
      z-index: 1;
      display: flex;
      justify-content: space-between;

      &::before {
        content: "";
        position: absolute;
        top: 5px;
        left: -1rem;
        right: -1rem;
        bottom: 5px;
        background-color: rgba(109, 109, 109, 0.7);
        z-index: -1;
      }
    }
  }

  .image-display {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    img {
      margin: 0.5rem;
      border-radius: 10px;
      box-shadow: var(--box-shadow);
    }
  }

  .bin-icon {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    color: red;
    cursor: pointer;
    height: 2rem;
    margin: 0.5rem;
  }
  .bin-parent {
    background-color: #aaa;
    height: 200px;
    width: 200px;
    margin: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .drag-drop:hover {
    cursor: move;
  }

  #preview {
    display: none;
  }

  div.list-group-item {
    cursor: move;
    position: relative;
  }
}
@media only screen and (orientation: portrait) {
  .modal {
    .image-display {
      img {
        width: 6rem;
        height: 6rem;
      }
    }
    div.content {
      .allergen {
        summary {
          margin: 0.5rem 0;
        }
      }
      .drop-content {
        flex-direction: column;
        .dropzone {
          width: 98%;
          height: 5rem;
        }
      }
    }
    .overlay {
      min-width: 90vw;
      .actions {
        .action {
          padding: 1rem 0.5rem;
        }
      }
    }
  }
}
</style>
